<!-- 教学与考试场地编辑 -->
<template>
    <div class="editTeachingField">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">教学与考试场地编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" id="form-1" :rules="rules" class="" inline label-width="150px">
            <el-form-item label="场地编号">
                <el-input v-model="form.number"  placeholder="请输入场地编号" disabled></el-input>
            </el-form-item>
            <el-form-item label="场地名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入场地名称"></el-input>
            </el-form-item>
            <el-form-item label="所属机构">
                <!-- <el-input v-model="form.belonging" placeholder="请输入所属机构"></el-input> -->
                <el-select v-model="form.belonging" filterable placeholder="请选择类型" :clearable="true">
                    <el-option v-for="item in form.belongingChoose" :key="item.id"
                         :label="item.name" :value="item.id">
                     </el-option>
                 </el-select>
            </el-form-item>
            <el-form-item label="上级场地">
                <el-input v-model="form.superior" placeholder="请输入上级场地"></el-input>
            </el-form-item>
            <el-form-item label="全称" prop="fullName">
                <el-input v-model="form.fullName" placeholder="请输入全称"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="address">
                <el-input v-model="form.address" placeholder="请输入地址"></el-input>
            </el-form-item>
            <el-form-item label="状态">
               <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                   <el-option v-for="item in form.stateChoose" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="教室容量" prop="studentNum">
                <el-input v-model="form.studentNum" placeholder="请输入教室容量"></el-input>
            </el-form-item>
            <el-form-item label="启用日期">
                <el-date-picker v-model="form.startDate" value-format="yyyy-MM-dd"  type="date" placeholder="请选择启用日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择类型" :clearable="true">
                    <el-option v-for="item in form.typeChoose" :key="item.value"
                         :label="item.label" :value="item.value">
                     </el-option>
                 </el-select>
            </el-form-item>
            <el-form-item label="停用日期">
                <el-date-picker v-model="form.stopUsing" value-format="yyyy-MM-dd"  type="date" placeholder="请选择停用日期">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
              form:{
                  number: "", //场地编号
                  name: "", //场地名称
                  belonging:"",//所属机构
                  belongingChoose:[],//所属机构选择
                  superior:"",//上级场地
                  fullName:"",//全称
                  address:"",//地址
                  stateChoose:[{
                          label: "可用",
                          value: "1"
                      },
                      {
                          label: "停用",
                          value: "2"
                      },], //状态选择
                  condition:"",//状态
                  studentNum:"",//学员人数
                  startDate:"",//启用日期
                  type:"",//类型
                  typeChoose:[{
                          label: "学院场地",
                          value: "1"
                      },{
                          label: "校外考点",
                          value: "2"
                      },{
                          label: "其它",
                          value: "3"
                      }],//类型选择
                  stopUsing:""//停用日期
              },
              rules:{
                  name:[
                      { required: true, message: '场地名称不能为空', trigger: 'blur' },
                  ],
                  fullName:[
                      { required: true, message: '全称不能为空', trigger: 'blur' },
                  ],
                  address:[
                      { required: true, message: '地址不能为空', trigger: 'blur' },
                  ],
                  studentNum:[
                      { required: true, message: '教室容量不能为空', trigger: 'blur' },
                  ],
              },
              bool:false,
            }
        },
        created() {
            // 所属机构
            this.$request({
                url: "/api/mechanism/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log("选择机构",res.data.list)
                this.form.belongingChoose = res.data.list
            })
            
            // 渲染初始数据
			this.$request({url: '/api/schoolarea/detail', method: 'POST', data: {id: this.$route.query.id}}).then(res => {
				var data = res.data.data
				var form = this.form
				form.number = data.site_number
				form.name = data.site_name
				form.belonging = data.org_id+''
				form.superior = data.pid
				form.fullName = data.all_name
				form.address = data.address
				form.condition = data.status+''
				form.studentNum = data.student_num
				form.startDate = data.start_time
                console.log(data.start_time,data.end_time)
				form.stopUsing = data.end_time
				form.type = data.type+''
			})
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        console.log(this.form)
                        var data = this.form
                        this.$request({url: '/api/schoolarea/edit', method: 'POST', data: {
                        	site_number: data.number,
                        	site_name: data.name,
                        	org_id: data.belonging,
                        	pid: data.superior,
                        	all_name: data.fullName,
                        	address: data.address,
                        	student_num: data.studentNum,
                        	start_time: data.startDate,
                        	end_time: data.stopUsing,
                        	type: data.type,
                        	status: data.condition,
							id: this.$route.query.id
                        }}).then(res => {
                        	console.log(res)
                        	if(res.code == 1){
								this.$message({
								  message: '修改列表数据成功',
								  type: 'success'
								})
								setTimeout(() => {
									this.goBack()
								}, 1500)
                        		
                        	}else{
                                 this.$message.error('修改列表数据失败');
                                 setTimeout(() => {
                                 	this.goBack()
                                 }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
			
            resetForm(form) {//取消
                this.$router.push({
                    path: "teachingField"
                })
                this.$refs[form].resetFields();
            }
        }
    }
</script>

<style scoped="scoped">
    .editTeachingField{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    .el-select {
        width: 300px;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
